<template>
  <div class="gallery" v-if="data && Object.keys(data).length > 0">
    <router-link class="close" to="/">Close</router-link>
    <router-link
      v-if="slideIndex == 0 && data.prev !== data.uid"
      class="prev"
      :to="{ name: 'project', params: { id: data.prev }, hash: '#last' }"
      >Previous</router-link
    >
    <div class="prev" v-if="slideIndex > 0" @click="prev">Previous</div>
    <router-link
      v-if="slideIndex == slides - 1 && data.next !== data.uid"
      class="next"
      :to="{ name: 'project', params: { id: data.next }, hash: '#0' }"
      >Next</router-link
    >
    <div v-if="slideIndex < slides - 1" class="next" @click="next()">Next</div>
    <swiper v-show="!loading" :options="swiperOption" ref="swiper" @slideChange="slideChanged">
      <swiper-slide v-for="file in data.files" :key="file.id">
        <div v-if="file.extension == 'txt'" class="text">
          <div class="text-inner" v-html="file.text"></div>
        </div>
        <div class="img-container" v-if="file.extension == 'jpg'">
          <img
            class="swiper-lazy"
            v-lazy="file.url"
            :data-srcset="file.srcset"
            :alt="data.title"
            @click="fullScreen(file.url)"
          />
        </div>
        <video-player
          v-if="file.extension == 'mov'"
          :file="file.url"
          :ext="file.extension"
        />
        <video-player
          v-if="file.extension == 'mp4'"
          :file="file.url"
          :ext="file.extension"
        />
        <audio-player
          v-if="file.extension == 'mp3'"
          :file="file.url"
          :ext="file.extension"
        />
        <pdf-viewer
          v-if="file.extension == 'pdf'"
          :file="file.url"
        ></pdf-viewer>
        <div class="caption">
          <span class="num smallcaps">{{ data.num }}.{{ slideIndex }}</span
          >&nbsp;
          <span class="title">{{ data.title }}</span>
          <span v-if="data.description">, {{ data.description }}</span>
          <span v-if="data.year">, {{ data.year }}</span>
          <br /><span v-if="file.gcaption">{{ file.gcaption }}</span>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="project-gallery" v-else>
    <div class="close-btn">Loading...</div>
  </div>
</template>

<script>
const VideoPlayer = () =>
  import(/* webpackChunkName: "VideoPlayer" */ "@/components/VideoPlayer");
const AudioPlayer = () =>
  import(/* webpackChunkName: "AudioPlayer" */ "@/components/AudioPlayer");
const PdfViewer = () =>
  import(/* webpackChunkName: "PdfViewer" */ "@/components/PdfViewer");
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from "axios";
export default {
  name: "Project",
  components: {
    swiper,
    swiperSlide,
    VideoPlayer,
    AudioPlayer,
    PdfViewer,
  },
  data() {
    return {
      slideIndex: 0,
      loading: true,
      text: false,
      data: null,
      swiperOption: {
        threshold: 20,
        allowTouchMove: false,
        breakpoints: {
          768: {
            allowTouchMove: true,
          },
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
          loadOnTransitionStart: true,
        },
      },
    };
  },
  mounted() {
    this.loadContent(this.$route.params.id);
    if (this.$route.hash) {
      setTimeout(() => {
            if(this.$route.hash == "#last") {
                   this.slideIndex = this.slides - 1;
            } else {
                  this.slideIndex = parseInt(this.$route.hash.substring(1));
            }
            this.swiper.slideTo(this.slideIndex, 0);
            this.loading = false;
      }, 500);
    }
  },
  destroyed() {
    this.loading = true;
  },
  computed: {
    slides() {
      return this.data.files.length;
    },
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  methods: {
    slideChanged() {
      this.$root.$emit("stop-playing");
    },
    closeGallery() {
      this.$root.$emit("stop-playing");
      this.$emit("gallery", false);
    },
    next() {
      this.slideIndex++;
      this.swiper.slideNext();
      this.$router.push({ hash: "#" + this.slideIndex });
    },
    prev() {
      this.slideIndex--;
      this.swiper.slidePrev();
      this.$router.push({ hash: "#" + this.slideIndex });
    },
    loadContent(uid) {
      axios
        .post(
          "/api/query",
          {
            query: `page('projects').children.find('${uid}')`,
            select: {
              title: true,
              uid: true,
              num: true,
              description: "page.description_md",
              year: true,
              prev: "page.prev.uid",
              next: "page.next.uid",
              text: "page.text_md.kirbytext",
              files: {
                query: "page.files.sortBy('sort', 'asc')",
                select: {
                  url: "file.resize(1800).url",
                  srcset: "file.srcset([600, 1200, 1800])",
                  thumb: "file.resize(400).url",
                  index: "file.num",
                  caption: true,
                  gcaption: true,
                  id: true,
                  extension: true,
                },
              },
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then((response) => {
          this.data = response.data.result;
          if (this.data.text) {
            let text = {
              text: this.data.text,
              index: 0,
              id: "text",
              extension: "txt",
            };
            this.data.files.unshift(text);
          }

        });
    },
  },
};
</script>

<style lang="scss">
.gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  z-index: 5;
  overflow: hidden;
  transition: transform 0.25s;
  .fsBox {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 999;
    cursor: zoom-out;
    background: black;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &.active {
      display: block;
    }
  }
  .close,
  .next,
  .prev {
    font-feature-settings: "c2sc", "smcp";
    font-variant: all-small-caps;
    text-transform: lowercase;
    color: white;
    position: absolute;
    cursor: pointer;
    user-select: none;
    transition: color 0.5s;
    z-index: 998;
    &:hover {
      color: #aaa;
    }
  }
  .next {
    top: 50%;
    right: calc(2 * var(--lh));
  }
  .prev {
    top: 50%;
    left: calc(2 * var(--lh));
  }
  .close {
    top: calc(2 * var(--lh));
    left: calc(2 * var(--lh));
  }
  @media only screen and (max-width: 1024px) {
    .next {
      top: calc(2 * var(--lh));
      right: calc(2 * var(--lh));
    }
    .prev {
      top: calc(2 * var(--lh));
      left: auto;
      right: calc(5 * var(--lh));
    }
  }
  .swiper-wrapper {
    height: 100vh;
    .swiper-slide {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      color: white;
      .caption {
        position: absolute;
        width: calc(100% - (4 * var(--lh)));
        color: white;
        bottom: calc(2 * var(--lh));
        left: calc(2 * var(--lh));
      }
      .img-container {
        padding: calc(4 * var(--lh)) calc(6 * var(--lh));
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        @media only screen and (max-width: 768px) {
          padding: calc(6 * var(--lh)) calc(2 * var(--lh));
        }
      }
      img {
        // padding: calc(6 * var(--lh));
        box-sizing: border-box;
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
        border: 0;
        outline: none;
        opacity: 0;
        // object-fit: contain;
        transition: opacity 0.25s;
        &.swiper-lazy-loaded {
          opacity: 1;
        }
      }
      .pdf {
        padding: calc(6 * var(--lh)) calc(8 * var(--lh));
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @media only screen and (max-width: 768px) and (orientation: portrait) {
          padding: calc(6 * var(--lh)) calc(2 * var(--lh));
        }
        object {
          width: 100%;
          height: 100%;
          overflow: scroll;
          @media only screen and (max-width: 768px) {
            object-fit: contain;
            background: white;
          }
        }
      }
      .text {
        padding: calc(6 * var(--lh)) calc(8 * var(--lh));
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -ms-overflow-style: none;
        // IE 10+
        scrollbar-width: none;
        // Firefox
        &::-webkit-scrollbar {
          display: none;
          // Safari and Chrome
        }
        @media only screen and (max-width: 768px) {
          padding: calc(6 * var(--lh)) calc(2 * var(--lh));
        }
        &:before {
          content: "";
          position: absolute;
          top: calc(6 * var(--lh));
          right: calc(8 * var(--lh));
          bottom: calc(6 * var(--lh));
          left: calc(8 * var(--lh));
          @media only screen and (max-width: 768px) {
            right: calc(2 * var(--lh));
            left: calc(2 * var(--lh));
          }
          box-sizing: border-box;
          pointer-events: none;
          box-shadow: inset 0 0 15px 15px black;
          z-index: 20;
        }
        .text-inner {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          // IE 10+
          scrollbar-width: none;
          // Firefox
          &::-webkit-scrollbar {
            display: none;
            // Safari and Chrome
          }
          padding: calc(4 * var(--lh));
          position: relative;
          @media only screen and (max-width: 768px) {
            padding: calc(2 * var(--lh));
          }
          p {
            margin-bottom: var(--lh);
          }
        }
      }
    }
  }
}
// .project-gallery {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: black;
//   color: white;
//   z-index: 99;
//   .close-btn {
//     font-feature-settings: "c2sc", "smcp";
//     position: absolute;
//     top: calc(var(--lh) * 2);
//     left: calc(var(--lh) * 2);
//     text-transform: uppercase;
//     color: white;
//   }
//   .navigation {
//     .next {
//       right: calc(var(--lh) * 2);
//     }
//     .prev {
//       left: calc(var(--lh) * 2);
//     }
//     .next,
//     .prev {
//       font-feature-settings: "c2sc", "smcp";
//       position: absolute;
//       top: 50%;
//       text-transform: uppercase;
//       cursor: pointer;
//       color: white;
//       transform: translate(0, -50%);
//     }
//     @media only screen and (max-width: 768px) {
//       display: flex;
//       position: absolute;
//       right: calc(var(--lh) * 2);
//       top: calc(var(--lh) * 2);
//       .next,
//       .prev {
//         position: static;
//         top: auto;
//         transform: none;
//       }
//       .prev {
//         margin-right: var(--lh);
//       }
//     }
//   }
//   .gallery {
//     padding: calc(6 * var(--lh));
//     height: 100%;
//     @media only screen and (max-width: 768px) {
//       padding: calc(var(--lh) * 6) calc(var(--lh) * 2);
//     }
//     .text {
//       width: 100%;
//       height: 100%;
//       &:before {
//         content: "";
//         position: absolute;
//         top: calc(var(--lh) * 6);
//         right: calc(var(--lh) * 6);
//         bottom: calc(var(--lh) * 6);
//         left: calc(var(--lh) * 6);
//         pointer-events: none;
//         -webkit-box-shadow: inset 0 0 15px 15px #000;
//         box-shadow: inset 0 0 15px 15px #000;
//         z-index: 20;
//         @media only screen and (max-width: 768px) {
//           right: calc(var(--lh) * 2);
//           left: calc(var(--lh) * 2);
//         }
//       }
//       .text-inner {
//         padding: calc(var(--lh) * 2);
//       }
//     }
//   }
//   .caption {
//     position: absolute;
//     width: 100%;
//     padding: calc(var(--lh) * 2);
//     bottom: 0;
//     left: 0;
//     box-sizing: border-box;
//     color: white;
//   }
// }
</style>
